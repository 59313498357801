<template lang="pug">
div(v-if='treatment') 
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("preTitle") }}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("reportTitle") }}
    v-spacer
    DeviceMini(v-if='isActive', :deviceId='isActiveTreatment.device.model.ID')
    v-spacer
    v-tooltip(v-if='isActive', bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn(fab, dark, color='orange', @click='terminate()', v-bind='attrs', v-on='on')
          v-icon mdi-square-rounded
      span {{ $t("terminateTreatment") }}
    div(v-else)
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='printDetail()')
            v-icon mdi-printer
        span {{ $t("actionPrintTooltip") }}
      v-tooltip(bottom) 
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, disabled, v-bind='attrs', v-on='on')
            v-icon mdi-check
        span {{ $t("trainingCompleted") }}
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-img.monitor-img(
          v-if='isActive',
          :src='streamImgSrc && streamImgSrc != "initiating" ? streamImgSrc : getCoverSrc()',
          height='auto'
        )
          .countdown(v-if='enabledCountdown') {{ countdownRes }}
        .d-flex.flex-no-wrap.justify-space-between
          v-row.ml-6(align='center')
            v-img(v-if='!isActive', :src='getCoverSrc()', width='64', max-width='64', height='36', max-height='36')
            v-card-title {{ treatment.app.name }}
          v-card-subtitle
            v-chip.ma-1(v-for='(item, i) in getTags(treatment.app.tags)', :key='i') {{ item }}
          v-spacer
          v-card-subtitle
            div(style='margin-top: 5px; font-size: 16px; color: #000000de') {{ $t("trainingScore") }}: {{ treatment.score }}
  v-row
    v-col(v-if='isActive && enabledEEG', xl='12', lg='12', md='12')
      EEGDiagram(:sn='isActiveTreatment.device.sn')
  v-row
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='teal', dark)
        .d-flex.flex-no-wrap.justify-space-between
          v-card-title {{ $t("trainingTitle") }}
          v-spacer
          .grey.rounded-bl-xl(v-if='!isActive')
            v-card-subtitle {{ $t("trainingEnd") }}
          .warning.rounded-bl-xl(v-else)
            v-card-subtitle {{ $t("isTraining") }}
        v-card-text
          div(v-if='isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer
              span {{ `${$t("completionCount")}` }}
              v-spacer
              span {{ this.records.length > 0 ? this.records[this.records.length - 1].data.isFinish : 0 }}
            //- .d-flex.flex-no-wrap.justify-space-between.my-4
            //-   span.iconfont.icon-icon-fanyingshijian(style='color: #fff; font-size: 22px; margin-right: 5px')
            //-   span 平均反应时间
            //-   v-spacer
            //-   span {{ this.records.length > 0 ? this.records[this.records.length - 1].data.duration : 0 + "s" }}
          div(v-if='!isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='!treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingNotCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer-outline
              span {{ `${$t("completionCount")}` }}
              v-spacer
              span {{ this.records.length > 0 ? this.records.length : 0 }}
            //- .d-flex.flex-no-wrap.justify-space-between.my-4
            //-   span.iconfont.icon-icon-fanyingshijian(style='color: #fff; font-size: 22px; margin-right: 5px')
            //-   span 平均反应时间
            //-   v-spacer
            //-   span {{ this.records.length > 0 ? this.averageTime(): 0 + "s" }}
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='cyan darken-3', dark)
        v-card-title {{ $t("configTitle") }}
        v-divider
        v-card-text

          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-monitor-eye
            span {{ $t("monitor") }}
            v-spacer
            span(v-if='config && config.monitor') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-school-outline
            span {{ $t("tips") }}
            v-spacer
            span(v-if='config && config.tips') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-school-outline
            span {{ $t("talk_text") }}
            v-spacer
            span(v-if='config && config.talk_text') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='blue-grey', dark)
        v-card-title {{  typeUsername == '1' ? $t("userNameTitle") : $t("patientTitle")  }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.name')
            v-icon.mr-1 mdi-account
            span {{ $t("patientName") }}
            v-spacer
            span {{ treatment.patient.name }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.patientNumber && bedNumberName != "false"')
            v-icon.mr-1 mdi-clipboard-account
            span {{  bedNumberName == 'true' ? (typeUsername == '1'? $t("userNameNumber") : $t("patientNumber")) : bedNumberName  }}
            v-spacer
            span {{ treatment.patient.patientNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.bedNumber && partition !== "false"')
            v-icon.mr-1 mdi-bed
            span {{ partition === "true" ? $t('second') : partition }}
            v-spacer
            span {{ treatment.patient.bedNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.birthday && enableAge')
            v-icon.mr-1 mdi-calendar
            span {{ $t("patientAge") }}
            v-spacer
            span {{ Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) : "" }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.gender')
            v-icon.mr-1 mdi-gender-male-female
            span {{ $t("patientGender") }}
            v-spacer
            span {{ treatment.patient.gender }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.maritalStatus') 
            v-icon.mr-1 mdi-human-male-female
            span {{ $t("patientMaritalStatus") }}
            v-spacer
            span {{ treatment.patient.maritalStatus }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.ethnicity')
            v-icon.mr-1 mdi-map-marker
            span {{ $t("patientEthnicity") }}
            v-spacer
            span {{ treatment.patient.ethnicity }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.education')
            v-icon.mr-1 mdi-school
            span {{ $t("patientEducation") }}
            v-spacer
            span {{ treatment.patient.education }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.remarks')
            v-icon.mr-1 mdi-pencil-box
            span {{ $t("patientRemarks") }}
            v-spacer
            span {{ treatment.patient.remarks }}
  //- v-row
  //-   v-col(xl='12', lg='12', md='12')
  //-     v-card.mx-auto(hover)
  //-       v-card-title {{ $t("recordGraph") }}
  //-       v-card-text
  //-         apexchart#pdfDomGraph(ref='chart', type='bar', height='400', :options='chartOptions', :series='series')
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("recordTitle") }}
        v-divider
        v-timeline(v-for='(item, index) in records', :key='index', align-top, dense)
          v-timeline-item(color='grey lighten-2', fill-dot, small)
            v-row
              v-col
                v-tooltip(bottom) 
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      v-icon.mr-1 mdi-star-box-multiple-outline
                      span {{ item.data.TopicIndex }}
                      .text-caption {{ item.data.Question }}
                  span.report-legend {{ $t("questionNumber") }}
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      v-icon.mr-1 mdi-lightbulb-on-outline
                      span {{ `${item.data.TopicOption}` }}
                    .text-caption {{ item.data.Option }}
                  span.report-legend {{ $t("options") }}
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      v-icon.mr-1 mdi-timer-outline
                      span {{ `${parseInt(item.data.LevelDuration)}s` }}
                  span.report-legend {{ $t("timeConsuming") }}

  TreatmentDetailReport(
    ref='treatmentReport',
    :patientId='$route.query.patientId',
    :treatmentId='$route.query.treatmentId',
    :printDom='printDom',
    :pdfDomGraph='pdfDomGraph'
  )
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { mapActions, mapGetters } from 'vuex'
import { treatment } from '../api'
import { connectStreamer } from '../lib/fmetp'
import DeviceMini from './DeviceMini'
import EEGDiagram from './EEGDiagram.vue'
import VueApexCharts from 'vue-apexcharts'
import { getStartTreatmentTime } from '../lib/start-treatment-time'
import TreatmentDetailReport from './Treatments/TreatmentDetailReport.vue'

export default {
  data: () => ({
    now: moment(),
    isActive: undefined,
    totalDuration: 0,
    socket: undefined,
    streamImgSrc: undefined,
    graphMsg: [],
    getScoreData: [],
    startTreatmentTime: '',
    averageData: {},
    thisUsersData: [],
    allScoreData: [],
    printDom: '',
    pdfDomGraph: '',
    timer: undefined,
    nowTimer: undefined,
    startTime: '',
    countdownRes: '',
    timestamp: 0
  }),

  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.nowTimer)
  },

  created() {
    momentDurationFormatSetup(moment)
    this.nowTimer = this.$mySetInterval(() => (this.now = moment()))
    this.fetchRecords(this.$route.params.id)
    this.startTreatmentTime = getStartTreatmentTime()

    if (this.enabledCountdown && this.isActive) {
      this.timer = this.$mySetInterval(() => {
        setTimeout(() => {
          this.startTime = this.calculateDuration(this.treatment.applicationStartTime)
          this.getTimeGap(JSON.parse(this.treatment.settings).timeLimit, this.startTime, this.treatment?.model.ID)
        }, 0)
      })
    }
  },

  async mounted() {
    await this.$nextTick()
    this.streamTreatment()
    const appId = parseInt(this.$route.query.appId)
    const patientId = parseInt(this.$route.query.patientId)
    const result = await treatment.getScore({ appId: appId, patientId: patientId })
    if (result.data.length > 0) {

      const data = result.data.filter(x => {
        return x.score !== 0
      })
      this.getScoreData = data
    }
    this.pdfDomGraph = document.querySelector('#pdfDomGraph')
  },

  destroyed() {
    this.socket.close()
  },

  methods: {
    ...mapActions({
      fetchRecords: 'records/indexByTreatment'
    }),

    calculateDuration(then) {
      if (this.now?.diff && moment(then).year() >= 2021) {
        return moment.duration(this.now.diff(then)).format(this.$t('hHourmmMinssSecond'))
      } else {
        return NaN
      }
    },

    // averageTime() {
    //   if (this.records.length > 0) {
    //     var sum = this.records.reduce(function (a, b) {
    //       return a.duration.split('s')[0] + b.duration.split('s')[0]
    //     })
    //     return sum / this.records.length
    //   }
    // },

    async getTimeGap(currentTime, pastTime, treatmentId) {
      let pattern = /\d+/g
      let match = pastTime.match(pattern)
      var minutes = 0
      var seconds = 0
      if (match.length == 1) {
        seconds = parseInt(match[0])
      } else if (match.length == 2) {
        minutes = parseInt(match[0])
        seconds = parseInt(match[1])
      }
      var now = new Date()
      var pastTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), minutes, seconds).getTime()
      var currentTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), currentTime, 0).getTime()
      this.timestamp = currentTimestamp - pastTimestamp
      if (this.timestamp > 0) {
        let date = new Date(this.timestamp)
        this.countdownRes = date.getMinutes() + this.$t('minute') + date.getSeconds() + this.$t('second')

        if (date.getMinutes() == 0 && date.getSeconds() == 0) {
          await treatment.terminateCountdown({ complete: true, id: treatmentId.toString() })
          clearInterval(this.timer)
          clearInterval(this.nowTimer)
        }
      } else {
        this.countdownRes = 0 + this.$t('minute') + 0 + this.$t('second')
      }
    },

    async getChartImage() {
      const dataURI = await this.$refs.chart.dataURI()
      const imgURI = dataURI.imgURI
      return imgURI
    },

    async printDetail() {
      this.$refs.treatmentReport.dialog = true
    },

    goBack() {
      this.$router.go(-1)
      clearInterval(this.timer)
      clearInterval(this.nowTimer)
    },

    async terminate() {
      const treatmentId = this.treatment?.model.ID
      await treatment.terminate(treatmentId)
      // this.socket.close()
      clearInterval(this.timer)
      clearInterval(this.nowTimer)
    },

    getCoverSrc() {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${this.treatment.app.cover}`
    },

    streamTreatment() {
      const treatmentId = this.treatment?.model.ID.toString()
      this.streamImgSrc = 'initiating'
      this.socket = connectStreamer(treatmentId, src => (this.streamImgSrc = src))
    },

    getTags(tags) {
      return tags.split(';')
    },

    calculateDurationBetween(start, end) {
      if (moment.duration(moment(end).diff(start))._milliseconds > 0) {
        if (start.slice(0, 10) !== '0001-01-01' && end) {
          return moment.duration(moment(end).diff(start)).format(this.$t('hHourmmMinssSecond'))
        } else {
          return NaN
        }
      } else {
        return 0 + this.$t('second')
      }
    },

    readableDuration(seconds) {
      return moment.duration(parseInt(seconds), 'seconds').format(this.$t('hHourmmMinssSecond'))
    },

    achieveGoals() {
      return this.records.length > 0 ? parseFloat(this.records[this.records.length - 1].data.currentAccuracy) * 100 + '%' : 0
    }
  },

  watch: {
    treatment: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.isActive = newVal.active
        if (!this.isActive) {
          clearInterval(this.timer)
          clearInterval(this.nowTimer)
          this.socket?.close()
        }
        this.totalDuration = this.calculateDurationBetween(newVal.applicationStartTime, newVal.applicationEndTime)
      }
    }
  },

  computed: {
    ...mapGetters({
      treatmentById: 'treatments/training',
      training: 'treatments/training',
      recordsByTreatmentId: 'records/recordsByTreatmentId',
      enabledEEG: 'setting/enabledEEG',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      bedNumberName: 'setting/bedNumberName',
      enableAge: 'setting/enableAge',
      enabledCountdown: 'setting/enabledCountdown'
    }),

    treatment() {
      return this.treatmentById(this.$route.params.id)
    },

    isActiveTreatment() {
      return this.training(this.$route.params.id)
    },

    config() {
      if (this.treatment.settings) {
        return JSON.parse(this.treatment.settings)
      } else {
        return null
      }
    },

    records() {
      return this.recordsByTreatmentId(this.$route.params.id)
    },

    series() {
      if (this.records.length < 0) return []
      return JSON.parse(this.treatment.settings).codename !== 'recognize_direction' ? [
        {
          color: '#008FFB',
          name: this.$t('correctRate'),
          type: 'bar',
          data: this.records.reduce((arr, x) => [...arr, x.data.duration], [])
        },

        {
          color: '#00E396',
          name: this.$t('correctRate'),
          type: 'bar',
          data: this.records.reduce((arr, x) => [...arr, x.data.correct_rate], [])
        }
      ] : [
        {
          color: '#008FFB',
          name: this.$t('reactionTime'),
          type: 'bar',
          data: this.records.reduce((arr, x) => [...arr, x.data.duration], [])
        }
      ]
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          stacked: false,
          toolbar: false
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          fontSize: '22px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 200,
        },
        stroke: {
          curve: 'smooth',
          width: [1, 2, 2]
        },
        plotOptions: {
          bar: {
            columnWidth: '5%',
            endingShape: 'rounded'
          }
        },
        yaxis: JSON.parse(this.treatment.settings).codename !== 'recognize_direction' ? [
          {
            forceNiceScale: true,
            type: 'area',
            labels: {
              style: {
                colors: '#607D8B',
                fontSize: '36px'
              },
              formatter: function (val) {
                return val + 's'
              }
            },
            title: {
              text: this.$t('currentMissionDuration'),
              rotate: 360,
              offsetX: 35,
              offsetY: 190,
              style: {
                color: '#607D8B',
                fontSize: '36px'
              }
            }
          },
          {
            opposite: true,
            tickAmount: 10,
            min: 0,
            max: 100,
            labels: {
              style: {
                colors: '#0097A7',
                fontSize: '36px'
              }
            },
            title: {
              text: this.$t('correctRate'),
              rotate: 360,
              offsetX: -35,
              offsetY: 190,
              style: {
                color: '#0097A7',
                fontSize: '36px'
              }
            }
          },
        ] : [
          {
            forceNiceScale: true,
            type: 'area',
            labels: {
              style: {
                colors: '#1E88E5',
                fontSize: '36px'
              },
              formatter: function (val) {
                return val + 's'
              }
            },
            title: {
              text: this.$t('currentMissionDuration'),
              rotate: 360,
              offsetX: -35,
              offsetY: 190,
              style: {
                color: '#1E88E5',
                fontSize: '36px'
              }
            }
          }
        ]
      }
    },
    ScoreSeries() {
      if (this.getScoreData.length < 0) return []
      return [
        {
          color: '#008FFB',
          name: this.$t('assessmentScore'),
          type: 'bar',
          data: this.getScoreData.reduce((arr, x) => [...arr, x.score], [])
        }
      ]
    },
    chartScoreOptions() {
      return {
        chart: {
          id: 'vuechart-example',
          stacked: false,
          toolbar: false
        },
        stroke: {
          curve: 'smooth',
          width: [4]
        },
        plotOptions: {
          bar: {
            columnWidth: '5%',
            endingShape: 'rounded'
          }
        },
        yaxis: {
          forceNiceScale: true,
          type: 'bar',
          labels: {
            style: {
              colors: '#008FFB',
              fontSize: '36px'

            }
          },
          title: {
            text: this.$t('historyScore'),
            rotate: 360,
            offsetX: 35,
            offsetY: 200,
            style: {
              color: '#008FFB',
              fontSize: '36px'
            }
          },
          tickAmount: 6,
          max: 100,
          min: 0
        },
        xaxis: {
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          categories: this.getScoreData.length === 0 ? [''] : this.getScoreData.map(x => x.model.CreatedAt.split('T')[0].replace(/-/g, '/'))
        }
      }
    }
  },

  components: {
    DeviceMini,
    EEGDiagram,
    apexchart: VueApexCharts,
    TreatmentDetailReport
  }
}
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-xaxis-texts-g {
  display: none !important;
}


.icon-zhengque {
  font-size: 20px;
}

.monitor-img {
  position: relative;
}

.countdown {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: red;
  font-weight: bold;
}
</style>
