<template lang="pug">
div
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()', v-if='authorized')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("return")}}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("viewreport") }}
    v-spacer
    div
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='print()')
            v-icon mdi-printer
        span {{ $t("actionPrintTooltip") }}
  v-card.content-page
    //- :style='{ width: authorized ? "800px" : "100%" }',
    div(:class='isOpen && authorized ? "page" : "mPage"')
      #divToPrint
        .page-top
          img.img-logo(:src='getSrc()', width='140', v-if='logo')
          .page-title
            .name {{ institution }}
            .subtitle {{ parseInt(this.$route.query.assessmentId) == 1?$t('MMSEMiniMentalStateTest'):$t('MoCAMontrealCognitiveAssessmentScale') }}
        .page-msg(v-if='printContentList && printContentList.name !== undefined')
          .row1
            .name(style='width: 266px') {{ $t("name") }}：{{ printContentList.name }}
            .gender(style='width: 266px; text-align: center') {{ $t("gender")}}：{{ printContentList.gender }}
            .hospitalNumber(style='width: 266px; text-align: right') {{ `${numberName ? numberName + "：" : $t("number") + "："}${printContentList.hospitalNumber}` }}
          .row2
            .age(v-if='enableAge', style='width: 266px') {{ $t("age")}}：{{ calcAge(printContentList.birthday) >= 0 ? calcAge(printContentList.birthday) : "" }}
            .bedNumberName(v-if='bedNumberName !== "false"', :style='{ width: "266px", textAlign: enableAge ? "center" : "left" }') {{ (typeUsername == "1" ? $t("userNameNumber") + ":" : $t("patientNumber") + "：") + printContentList.patientNumber }}
            .partition(v-if='partition !== "false"', :style='{ width: "266px", textAlign: enableAge ? "right" : "center" }') {{ (partition == "true" ? $t("subregion") + "：" : partition + "：") + printContentList.bedNumber }}
        .page-table(v-if='parseInt(this.$route.query.assessmentId) == 1 && authorized && isOpen')
          table(width='100%', cellspacing='0', style='margin-top: 40px')
            thead
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='center', width='70') {{ $t("number") }}
                td(align='left') {{ $t("scoringItems") }}
                td(align='center') {{ $t("scoreCorrectly") }}
            tbody
              tr(
                v-for='(item, index) in leftTableData',
                :key='index',
                height='30',
                :style='{ background: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }'
              )
                td(align='center') {{ index + 1 }}
                td(align='left') {{ item.question }}
                td(align='center') {{ item.answer }}
          .line
          table(width='100%', cellspacing='0', style='margin-top: 40px')
            thead
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='center', width='70') {{ $t("number") }}
                td(align='left') {{ $t("scoringItems") }}
                td(align='center') {{ $t("scoreCorrectly") }}
            tbody
              tr(
                v-for='(item, index) in rightTableData',
                :key='index',
                height='30',
                :style='{ background: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }'
              )
                td(align='center') {{ item.id }}
                td(align='left') {{ item.data.question }}
                td(align='center') {{ item.data.answer }}
        .page-table(v-if='parseInt(this.$route.query.assessmentId) == 2 && authorized && isOpen')
          table(width='100%', cellspacing='0', style='margin-top: 40px')
            thead
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='center', width='70') {{ $t("number") }}
                td(align='left') {{ $t("scoringItems") }}
                td(align='center') {{ $t("scoreCorrectly") }}
            tbody
              tr(
                v-for='(item, index) in leftTableData',
                :key='index',
                height='30',
                :style='{ background: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }'
              )
                td(align='center') {{ index + 1 }}
                td(align='left') {{ item.question[index] }}
                td(align='center') {{ item.answer }}
          .line
          table(width='100%', cellspacing='0', style='margin-top: 40px')
            thead
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='center', width='70') {{ $t("number") }}
                td(align='left') {{ $t("scoringItems") }}
                td(align='center') {{ $t("scoreCorrectly") }}
            tbody
              tr(
                v-for='(item, index) in rightTableData',
                :key='index',
                height='30',
                :style='{ background: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }'
              )
                td(align='center') {{ item.id }}
                td(align='left') {{ item.data.question[item.id - 1] }}
                td(align='center') {{ item.data.answer }}
        .page-table(v-if='!authorized || !isOpen')
          table(width='100%', cellspacing='0', style='margin-top: 40px')
            thead
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='center', width='70') {{ $t("number") }}
                td(align='left', width='190') {{ $t("scoringItems") }}
                td(align='center') {{ $t("scoreCorrectly") }}
            tbody
              tr(
                v-for='(item, index) in tableData',
                :key='index',
                height='30',
                :style='{ background: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }'
              )
                td(align='center') {{ index + 1 }}
                td(align='left') {{ parseInt($route.query.assessmentId) == 2 ? item.question[index] : item.question }}
                td(align='center') {{ item.answer }}
        .page-result(v-if='parseInt(this.$route.query.assessmentId) == 1')
          table(width='100%', cellspacing='0', style='margin-top: 40px; border-color: #e7e7e7; font-size: 14px', border='1')
            thead
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='left', colspan='6', style='padding-left: 10px') {{ $t("consequence") }}
            tbody(style='background: #f5f5f5')
              tr(height='30')
                td(align='center', rowspan='4') {{ $t("score") }}
                td(align='center', rowspan='2', :style='{ color: color, fontSize: "30px" }') {{ mmseScore }}
                td(align='center', rowspan='4') {{ $t("referenceDemarcation") }}
                td(align='center') 27≤{{ $t("scores") }}≤30
                td(align='center') {{ $t("normalCognitiveFunction") }}
              tr(height='30')
                td(align='center') 21≤{{ $t("scores") }}≤26
                td(align='center') {{ $t("mildCognitiveImpairment") }}
              tr(height='30')
                td(align='center', rowspan='2') {{ tipText }}
                td(align='center') 10≤{{ $t("scores") }}≤20
                td(align='center') {{ $t("moderateCognitiveImpairment") }}
              tr(height='30')
                td(align='center') 0≤{{ $t("scores") }}≤9
                td(align='center') {{ $t("severeCognitiveImpairment") }}
        .page-result(v-else)
          table(width='100%', cellspacing='0', style='margin-top: 40px; border-color: #e7e7e7; font-size: 14px', border='1')
            thead
              tr(style='background: #2980ba; height: 30px; color: white')
                td(align='left', colspan='6', style='padding-left: 10px') {{ $t("consequence") }}
            tbody(style='background: #f5f5f5')
              tr(height='30')
                td(align='center', rowspan='4') {{ $t("score") }}
                td(align='center', rowspan='2', :style='{ color: color, fontSize: "30px" }') {{ mocaScore }}
                td(align='center', rowspan='4') {{ $t("referenceDemarcation") }}
                td(align='center', rowspan='2') {{ $t("scores") }}≥26
                td(align='center', rowspan='2') {{ $t("normalCognitiveFunction") }}
              tr(height='30')
              tr(height='30')
                td(align='center', rowspan='2') {{ tipText }}
                td(align='center', rowspan='2') {{ $t("scores") }}<26
                td(align='center', rowspan='2') {{ $t("cognitiveDysfunction") }}
              tr(height='30')
      #bottomFont.page-bottom
        .left
          span {{ $t("printTime") }}：{{ calcDateTime(new Date()) }}
          span *{{ $t("thisReportClinicalReferenceOnly") }}
        .right
          span {{ $t("signature") }}：
</template>

<script>
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import { printPDF } from '../../lib/print'
import { mapGetters, mapState } from 'vuex'
import { gauge } from '../../api'
export default {
  data() {
    return {
      printContentList: {},
      tableData: [],
      leftTableData: [],
      rightTableData: [],
      mmseScore: 0,
      mocaScore: 0,
      id: 0,
      color: '',
      education: '',
      tipText: '',
      isOpen: true
    }
  },
  watch: {
    patientsWA: function (val) {
      this.printContentList = val.find(x => {
        return x.model.ID === this.id
      })
    }
  },
  mounted() {
    this.getTableData()
    this.resize()
    window.addEventListener('resize', this.resize, false)
  },
  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    print() {
      const printDom = document.querySelector('#divToPrint')
      const bottomFontDom = document.querySelector('#bottomFont')
      const content = {
        printDom: printDom,
        bottomFontDom: bottomFontDom,
        type: 'scale',
        isOpen: this.isOpen
      }
      printPDF(content)
    },
    goBack() {
      if (this.$route.query.type == '1') {
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    },
    getSrc() {
      return `${VUE_APP_SERVER_ENDPOINT}/assets/${this.logo}`
    },
    calcAge(dateString) {
      var birthday = +new Date(dateString)
      return ~~((Date.now() - birthday) / 31557600000)
    },
    calcDateTime(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    async getTableData() {
      const result = await gauge.getResult(parseInt(this.$route.params.id))
      this.id = result.data.patientId
      if (this.patientsWA.length > 0) {
        this.printContentList = this.patientsWA.find(item => {
          return item.model.ID === this.id
        })
      }
      if (parseInt(this.$route.query.assessmentId) == 1) {
        const num = result.data.score
        this.mmseScore = num
        this.tableData = JSON.parse(result.data.data)
        for (let i = 0; i < this.tableData.length / 2; i++) {
          this.leftTableData.push(this.tableData[i])
        }
        for (let i = 0; i < this.tableData.length / 2; i++) {
          this.rightTableData.unshift({
            data: this.tableData[this.tableData.length - i - 1],
            id: this.tableData.length - i
          })
        }
      } else {
        const data = JSON.parse(result.data.data)
        this.tableData = data.slice(0, data.length - 1)
        for (let i = 0; i < this.tableData.length / 2; i++) {
          this.leftTableData.push(this.tableData[i])
        }
        for (let i = 0; i < this.tableData.length / 2; i++) {
          this.rightTableData.unshift({
            data: this.tableData[this.tableData.length - i - 1],
            id: this.tableData.length - i
          })
        }
        var num = 0
        this.education = data.slice(data.length - 1, data.length)[0]
        if (this.education == this.$t('university') || this.education == this.$t('master') || this.education == this.$t('PhD')) {
          num = result.data.score
        } else {
          num = result.data.score < 30 ? result.data.score + 1 : result.data.score
        }
        this.mocaScore = num
      }
      if (parseInt(this.$route.query.assessmentId) === 1) {
        if (this.mmseScore >= 27 && this.mmseScore <= 30) {
          this.color = '#4BD549'
          this.tipText = this.$t('normalCognitiveFunction')
        } else if (this.mmseScore >= 21 && this.mmseScore <= 26) {
          this.color = '#FAD984'
          this.tipText = this.$t('mildCognitiveImpairment')
        } else if (this.mmseScore >= 10 && this.mmseScore <= 20) {
          this.color = '#FF9800'
          this.tipText = this.$t('moderateCognitiveImpairment')
        } else {
          this.color = '#E02A1F'
          this.tipText = this.$t('severeCognitiveImpairment')
        }
      } else {
        if (this.mocaScore >= 26) {
          this.color = '#4BD549'
          this.tipText = this.$t('normalCognitiveFunction')
        } else {
          this.color = '#E02A1F'
          this.tipText = this.$t('cognitiveDysfunction')
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      scaleTitle: 'setting/scaleTitle',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      enableAge: 'setting/enableAge',
      authorized: 'users/authorized'
    }),
    ...mapState({
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items
    })
  }
}
</script>

<style lang='scss' scoped>
.content-page {
  width: 880px;
  padding: 20px 0 20px 0;
  margin: 0 auto;
}

.mPage {
  width: 100%;
  padding: 0 20px;
}

// .mobilePage {
//   width: 716px;
//   margin: 0 auto;
//   .page-table {
//     display: flex;
//   }
// }
.page {
  width: 800px;
  margin: 0 auto;
}

#divToPrint {
  overflow: auto;
}

.page-top {
  position: relative;

  .img-logo {
    position: absolute;
    left: 0;
    top: -30px;
  }

  .page-title {
    width: 100%;
    text-align: center;

    .name {
      font-size: 32px;
      margin-top: 30px;
    }

    .subtitle {
      font-size: 24px;
      margin-top: 20px;
    }
  }
}

.page-msg {
  width: 100%;
  height: 72px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 50px;
  padding-top: 12px;

  .row1 {
    display: flex;
  }

  .row2 {
    display: flex;
  }
}

.page-table {
  display: flex;
  font-size: 14px;

  .line {
    margin-top: 40px;
    height: 560px;
    width: 1px;
    border: 1px solid rgba(0, 0, 0, 0.384);
  }
}

.page-bottom {
  margin-top: 50px;
  display: flex;

  .left {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .right {
    margin-left: 300px;
  }
}
</style>