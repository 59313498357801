import { treatment } from '../../api'

const mapTreatment = data => ({
  model: {
    ID: data.id,
    CreatedAt: data.created_at,
    UpdatedAt: data.updated_at,
    DeletedAt: data.deleted_at
  },
  active: data.active,
  appId: data.app_id,
  deviceId: data.device_id,
  patientId: data.patient_id,
  settings: data.settings
    .split('')
    .join(''),
  applicationStartTime: data.application_start_time.split(/\+|Z/)[0],
  numberOfMissions: data.number_of_missions,
  missionId: data.mission_id,
  missionDifficulty: data.mission_difficulty,
  missionStartTime: data.mission_start_time,
  missionTargets: data.mission_targets,
  applicationEndTime: data.application_end_time.split(/\+|Z/)[0],
  complete: data.complete,
  score: data.score,
  scoreOverwrite: data.score_overwrite,
  plan: data.plan,
  effect: data.effect
})

export default {
  namespaced: true,
  state: {
    items: []
  },

  getters: {
    activeTreatments: (state, getters) => {
      return getters.treatmentsWADP.length != 0 ? getters.treatmentsWADP.filter(x => x.active == true) : []
    },

    devicesNA: (state, getters) => {
      return getters.activeTreatments.map(x => x.deviceId)
    },

    patientsNA: (state, getters) => {
      return getters.activeTreatments.map(x => x.patientId)
    },

    treatmentsWADP: (state, getter, rootState) => {

      return [...state.items].map(x => {
        if (rootState['apps'].appsAllData != '') {
          const app = rootState['apps'].appsAllData.find(y => y.model.ID == x.appId)
          if (app) x.app = app
        }
        if (rootState['patients'].items != null) {
          const patient = rootState['patients'].items.find(y => y.model.ID == x.patientId)
          if (patient) x.patient = patient
        }
        if (rootState['devices'].items != null) {
          const device = rootState['devices'].items.find(y => y.model.ID == x.deviceId)
          if (device) x.device = device
        }
        return x
      })
    },

    training: (state, getter, rootState) => id => {
      const item = state.items.find(x => x.model.ID == id)
      console.log(rootState)
      if (!item) {
        console.log("training item in null")
        return
      }
      const app = rootState['apps'].appsAllData.find(y => y.model.ID == item.appId)
      if (!app) {
        console.log("training app in null")
        return
      }
      const patient = rootState['patients'].items.find(y => y.model.ID == item.patientId)
      if (!patient) {
        console.log("training patient in null")
        return
      }
      const device = rootState['devices'].items.find(y => y.model.ID == item.deviceId)
      if (device) item.device = device
      item.app = app
      item.patient = patient

      return item
    },

    treatmentById: (state, getter, rootState) => id => {

      const item = state.items.find(x => x.model.ID == id)

      if (!item) return
      const app = rootState['apps'].appsAllData.find(y => y.model.ID == item.appId)

      if (!app) return
      const patient = rootState['patients'].items.find(y => y.model.ID == item.patientId)
      if (!patient) return
      item.app = app
      item.patient = patient
      return item
    },

    treatmentsByPatientId: (state, getter) => patientId => {
      const items = getter.treatmentsWADP.filter(x => x.patientId == patientId)
      return items
    }
  },

  actions: {
    async index({ commit }) {
      var userId = 0
      if (JSON.parse(window.localStorage.getItem('vuex')).users.role != undefined) {
        const logToken = JSON.parse(window.localStorage.getItem('vuex'))
        const adminId = logToken.users.userId
        const role = logToken.users.role
        userId = parseInt(role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId)
      } else {
        userId = 1
      }
      const response = await treatment.index(1000, '', '', '', '', userId)
      commit('index', response.data)
    },
    async searchTreatments({ commit }, payload) {
      console.log(payload)
      commit('index', payload)

    },
    async updateTreatment({ commit }, treatmentId) {
      const response = await treatment.getTreatment(treatmentId)
      commit('edit', mapTreatment(response.data))
    },
    async pgevent({ commit }, payload) {
      switch (payload.action) {
        case 'INSERT':
          commit('add', mapTreatment(payload.data))
          break
        case 'UPDATE':
          commit('edit', mapTreatment(payload.data))
          break
        case 'DELETE':
          commit('remove', payload.data.id)
          break
        default:
          break
      }
    }
  },

  mutations: {

    index(state, payload) {
      state.items = payload
    },

    add(state, payload) {
      state.items.splice(0, 0, payload)
    },

    edit(state, payload) {

      state.items.splice(
        state.items.findIndex(x => x.model.ID == payload.model.ID),
        1,
        payload
      )
    },

    remove(state, payload) {
      state.items.splice(
        state.items.findIndex(x => x.model.ID == payload),
        1
      )
    }
  }
}
