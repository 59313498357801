<template lang="pug">
div
  v-row(align='center')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-monitor-eye
        | {{ $t("monitor") }}
        v-spacer
        v-switch(inset, v-model='monitor')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/icon-voice.png', width='24', style='margin-right: 5px')
        | {{ $t("playVoiceCategory") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='videoType',
          :items='playVideos',
          item-text='text',
          item-value='value'
        )

  v-row(align='center')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/score.png', width='24', style='margin-right: 5px')
        | {{ $t("score") }}
        v-spacer
        v-switch(inset, v-model='scoreSwitch')
    v-col.px-6(cols='6')
      v-row.setting-row(align='center', no-gutters)
        v-icon(left) mdi-timer-outline
        | {{ $t("timeLimit") }}
        v-spacer
        v-select.outlined.mr-3(
          reverse,
          style='max-width: 100px',
          v-model='timeLimit',
          :items='timeLimits',
          item-text='text',
          item-value='value'
        )
    v-col.px-6(cols='6') 
      v-row.setting-row(align='center', no-gutters)
        img(src='@/assets/icon-breathe.png', width='24', style='margin-right: 5px')
        | {{ $t("breathMonitor") }}
        v-spacer
        v-switch(inset, v-model='breathMonitor')
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['packageName', 'appCodename', 'voice', 'video', 'type'],

  mounted() {
    this.videoType = this.voice
    if (this.type == 2 || this.type == 5 || this.type == 6 || this.type == 7) {
      this.timeLimit = 15
    } else if (this.type == 3 || this.type == 4) {
      this.timeLimit = 20
    } else {
      this.timeLimit = -1
    }
  },

  data: vm => ({
    monitor: true,
    scoreSwitch: false,
    breathMonitor: false,
    timeLimit: 15,
    timeLimits: [
      { text: vm.$t('5min'), value: 5 },
      { text: vm.$t('10min'), value: 10 },
      { text: vm.$t('15min'), value: 15 },
      { text: vm.$t('20min'), value: 20 },
      { text: vm.$t('30min'), value: 30 }
    ],
    videoType: 0,
    playVideos: [
      { text: vm.$t('close'), value: 0 },
      { text: vm.$t('Mindfulness'), value: 1 },
      { text: vm.$t('hypnosis'), value: 2 }
    ]
  }),

  methods: {
    getSettings() {
      return JSON.stringify({
        packageName: this.packageName,
        codename: this.appCodename,
        monitor: this.monitor,
        scoreSwitch: this.scoreSwitch,
        breathMonitor: this.breathMonitor,
        voiceType: this.videoType,
        videoType: this.video,
        timeLimit: this.timeLimit
      })
    }
  },

  computed: {
    ...mapGetters({
      enabledCountdown: 'setting/enabledCountdown'
    })
  }
}
</script>

<style lang="sass">
.setting-row
  height: 50px
</style>

