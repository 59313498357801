<template lang="pug">
div
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | 返回
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title 查看报告
    v-spacer
    div
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='print()')
            v-icon mdi-printer
        span 打印报告
  v-card.content-page
    div(:class='isOpen ? "page" : "mPage"')
      #divToPrint
        .page-top
          img.img-logo(:src='getSrc()', width='140', v-if='logo')
          .page-title
            .name {{ institution }}
            .subtitle {{ reportName }}
        .page-msg(v-if='printContentList.name !== undefined')
          .row1
            .name(style='width: 266px') 姓名：{{ printContentList.name }}
            .gender(style='width: 266px; text-align: center') 性别：{{ printContentList.gender }}
            .hospitalNumber(style='width: 266px; text-align: right') {{ `${numberName ? numberName + "：" : "编号："}${printContentList.hospitalNumber}` }}
          .row2
            .age(v-if='enableAge', style='width: 266px') 年龄：{{ calcAge(printContentList.birthday) >= 0 ? calcAge(printContentList.birthday) : "" }}
            .bedNumberName(v-if='bedNumberName !== "false"', :style='{ width: "266px", textAlign: enableAge ? "center" : "left" }') {{ (typeUsername == "1" ? "用户号:" : "病床号：") + printContentList.patientNumber }}
            .partition(v-if='partition !== "false"', :style='{ width: "266px", textAlign: enableAge ? "right" : "center" }') {{ (partition == "true" ? "分区：" : partition + "：") + printContentList.bedNumber }}
        .treatment-msg
          .treatment-title1 训练信息
          .content
            span 训练项目：{{ treatment.app.name }}
            span 得分：{{ treatment.scoreOverwrite }}
            span 治疗日期：{{ calcEndDateTime(treatment.applicationEndTime, displayTime) }}
        .treatment
          .treatment-title 治疗目标
          .treatment-content(style='white-space: pre-wrap') {{ treatmentTarget }}
        .treatment
          .treatment-title 治疗内容
          .treatment-content {{ treatmentContent }}
        .treatment(v-if='treatment.app.codename!=="cognitiveassessment"')
          .treatment-title 治疗效果
          .treatment-content {{ effect }}
        //- #resultGraph(v-if='resultGraph')
        //-   .subtitle(style='font-weight: bold') {{ resultGraphTitle }}
        //-   img(:src='resultGraphUrl', style='width: 900px')
        #scoreGraph(v-if='scoreGraph')
          .subtitle(style='font-weight: bold') {{ scoreGraphTitle }}
          img(:src='scoreGraphUrl', style='width: 800px')
        #reportGraph(v-if='reportGraph')
          .subtitle(style='font-weight: bold') {{ reportGraphTitle }}
          img(:src='reportGraphUrl', style='width: 900px')
        .treatment(v-if='treatment.app.codename!=="cognitiveassessment"')
          .treatment-title 下次治疗计划
          .treatment-content {{ nextTreatmentPlan }}
      #bottomFont.page-bottom
        .left
          span 打印时间：{{ calcDateTime(new Date()) }}
          span *此报告仅供临床参考
        .right
          span(@click='test') 签字：
  </template>

<script>
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'
import { printPDF } from '../../lib/print'
import { mapGetters } from 'vuex'
import html2Canvas from 'html2canvas'
export default {
  data() {
    return {
      printContentList: {},
      treatmentTarget: '',
      treatmentContent: '',
      effect: '',
      nextTreatmentPlan: '',
      imgUrl: '',
      isImg: true,
      imgTitle: '',
      isOpen: true,
      scoreGraph: false,
      scoreGraphTitle: '',
      scoreGraphUrl: '',
      reportGraph: false,
      reportGraphTitle: '',
      reportGraphUrl: '',
      resultGraph: false,
      resultGraphTitle: '',
      resultGraphUrl: '',
    }
  },
  watch: {
    patientsWA(val) {
      this.printContentList = val.find(item => {
        return item.model.ID === parseInt(this.$route.query.patientId)
      })
    },
    treatment(val) {
      this.treatmentTarget =
        val.target == undefined || val.target == '' ? val.app.target : val.target
      this.treatmentContent =
        val.content == undefined || val.content == '' ? val.app.content : val.content
      this.effect = val.effect == '' || val.effect == undefined ? '良好' : val.effect
      this.nextTreatmentPlan = val.plan == '' || val.effect == undefined ? '继续该治疗' : val.plan
    }
  },
  mounted() {
    if (this.patientsWA.length > 0) {
      this.printContentList = this.patientsWA.find(item => {
        return item.model.ID === parseInt(this.$route.query.patientId)
      })
    }
    if (this.treatment !== undefined) {
      this.treatmentTarget =
        this.treatment.target == undefined || this.treatment.target == '' ? this.treatment.app.target : this.treatment.target
      this.treatmentContent =
        this.treatment.content == undefined || this.treatment.content == '' ? this.treatment.app.content : this.treatment.content
      this.effect = this.treatment.effect == '' || this.treatment.effect == undefined ? '良好' : this.treatment.effect
      this.nextTreatmentPlan = this.treatment.plan == '' || this.treatment.effect == undefined ? '继续该治疗' : this.treatment.plan
    }
    const pdfDomGraph = window.localStorage.getItem('pdfDomGraph')
    const printDom = window.localStorage.getItem('printDom')
    const pdfDomResult = window.localStorage.getItem('pdfDomResult')
    //const detailName = JSON.parse(this.$route.query.detailName)
    // if (pdfDomGraph == 'undefined' && printDom !== 'undefined' && detailName == 'cognitive') {
    //   this.isImg = true
    //   this.imgTitle = '得分情况'
    //   this.getGraph()
    // } else if (printDom == 'undefined' && pdfDomGraph !== 'undefined' && detailName == 'cognitive') {
    //   this.isImg = true
    //   this.imgTitle = '报告图表'
    //   this.getReportGraph()
    // } else if (printDom == 'undefined' && pdfDomGraph !== 'undefined' && detailName == '') {
    //   this.isImg = true
    //   this.imgTitle = '报告图表'
    //   this.getReportGraph()
    // } else if (pdfDomGraph == 'undefined' && printDom == 'undefined' && detailName == '') {
    //   this.isImg = false
    // }
    if (pdfDomResult !== 'undefined') {
      this.resultGraph = true
      this.resultGraphTitle = this.$t("userAchievement")
      this.getResultGraph()
    }
    if (printDom !== 'undefined') {
      this.scoreGraph = true
      this.scoreGraphTitle = this.$t("recordScoreGraph")
      this.getGraph()
    }
    if (pdfDomGraph !== 'undefined') {
      this.reportGraph = true
      this.reportGraphTitle = this.$t("recordGraph")
      this.getReportGraph()
    }
    this.resize()
    window.addEventListener('resize', this.resize, false)
  },
  methods: {
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    test() {
      this.getGraph()
    },
    goBack() {
      this.$router.go(-1)
    },
    print() {
      const printDom = document.querySelector('#divToPrint')
      const bottomFontDom = document.querySelector('#bottomFont')
      const content = {
        printDom: printDom,
        bottomFontDom: bottomFontDom,
        type: 'treatment',
        isOpen: this.isOpen
      }
      printPDF(content)
    },
    getSrc() {
      return `${VUE_APP_SERVER_ENDPOINT}/assets/${this.logo}`
    },

    calcDateTime(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    calcAge(dateString) {
      var birthday = +new Date(dateString)
      return ~~((Date.now() - birthday) / 31557600000)
    },
    calcEndDateTime(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    getResultGraph() {
      // const dom = document.querySelector('#graph')
      const storedHTML = window.localStorage.getItem('pdfDomResult')
      // 创建一个新的 DOM 元素
      const tempElement = document.createElement('div')
      // 将存储的 HTML 内容赋值给新元素的 innerHTML 属性
      tempElement.innerHTML = storedHTML
      // 获取新元素作为 DOM 元素进行操作
      const domElement = tempElement
      const a = document.body.appendChild(domElement)
      html2Canvas(a, {

        scale: 4,
        dpi: window.devicePixelRatio * 4,
        useCORS: true
      }).then(canvas => {
        // 把生成的base64位图片上传到服务器,生成在线图片地址
        const imageData = canvas.toDataURL()
        this.resultGraphUrl = imageData
        a.style.display = 'none'
      })
    },
    getGraph() {
      // const dom = document.querySelector('#graph')
      const storedHTML = window.localStorage.getItem('printDom')
      // 创建一个新的 DOM 元素
      const tempElement = document.createElement('div')
      // 将存储的 HTML 内容赋值给新元素的 innerHTML 属性
      tempElement.innerHTML = storedHTML
      // 获取新元素作为 DOM 元素进行操作
      const domElement = tempElement.firstChild
      const a = document.body.appendChild(domElement)
      html2Canvas(a, {

        scale: 4,
        dpi: window.devicePixelRatio * 4,
        useCORS: true
      }).then(canvas => {
        // 把生成的base64位图片上传到服务器,生成在线图片地址
        const imageData = canvas.toDataURL()
        this.scoreGraphUrl = imageData
        a.style.display = 'none'
      })
    },
    getReportGraph() {
      // const dom = document.querySelector('#graphChild')
      const storedHTML = window.localStorage.getItem('pdfDomGraph')
      // 创建一个新的 DOM 元素
      const tempElement = document.createElement('div')
      // 将存储的 HTML 内容赋值给新元素的 innerHTML 属性
      tempElement.innerHTML = storedHTML
      // 获取新元素作为 DOM 元素进行操作
      const domElement = tempElement.firstChild
      const a = document.body.appendChild(domElement)
      html2Canvas(a, {

        scale: 4,
        dpi: window.devicePixelRatio * 4,
        useCORS: true
      }).then(canvas => {
        // 把生成的base64位图片上传到服务器,生成在线图片地址
        const imageData = canvas.toDataURL()
        this.reportGraphUrl = imageData
        a.style.display = 'none'
      })
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      treatmentById: 'treatments/treatmentById',
      enableAge: 'setting/enableAge'
    }),
    treatment() {
      return this.treatmentById(this.$route.params.id)
    }
  }
}
</script>

<style lang='scss' scoped>
.content-page {
  width: 880px;
  padding: 20px 0 20px 0;
  margin: 0 auto;
}

.mPage {
  width: 100%;
  padding: 0 20px;
}

#divToPrint {
  overflow: auto;
}

.page-top {
  position: relative;

  .img-logo {
    position: absolute;
    left: 0;
    top: -30px;
  }

  .page-title {
    width: 100%;
    text-align: center;

    .name {
      font-size: 32px;
      margin-top: 30px;
    }

    .subtitle {
      font-size: 24px;
      margin-top: 20px;
    }
  }
}

.page-msg {
  width: 100%;
  height: 72px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 50px;
  padding-top: 12px;
  font-size: 16px;

  .row1 {
    display: flex;
  }

  .row2 {
    display: flex;
  }
}

.treatment-msg {
  margin-top: 20px;

  .treatment-title1,
  .contnt {
    width: 100px;
    height: 30px;
    background: #2980ba;
    color: #fff;
    line-height: 30px;
    padding-left: 10px;
  }

  .content {
    height: 40px;
    background: #f5f5f5;
    color: #000;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 10px;
  }
}

.treatment {
  .treatment-title {
    width: 100px;
    height: 30px;
    background: #2980ba;
    color: #fff;
    line-height: 30px;
    padding-left: 10px;
  }

  .treatment-content {
    height: 80px;
    background: #f5f5f5;
    padding: 0 10px 0 10px;
  }
}

.page-bottom {
  margin-top: 50px;
  display: flex;

  .left {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .right {
    margin-left: 300px;
  }
}

.page {
  width: 800px;
  margin: 0 auto;
  font-size: 12px;
}
</style>