<template lang="pug">
div
  v-dialog(v-model='dialog', width='800', height='200', persistent)
    v-card
      v-card-title.primary.headline.mb-12
        h4(style='color: white') {{ $t("treatmentSheet") }}
      v-card-text
        v-form(ref='form')
          v-row
            .title {{ $t("treatmentGoals") }}
            v-col(cols='12', sm='12', md='12')
              v-textarea(solo, auto-grow, dense, row-height='12', v-model='treatmentTarget')
          v-row
            .title {{ $t("treatmentContent") }}
            v-col(cols='12', sm='12', md='12')
              v-textarea(solo, auto-grow, dense, row-height='12', v-model='treatmentContent')
          v-row
            .title {{ $t("treatmentContent") }}
            v-col(cols='12', sm='12', md='12')
              v-textarea(solo, auto-grow, dense, row-height='12', v-model='effect')
          v-row
            .title {{ $t("nextTreatmentPlan") }}
            v-col(cols='12', sm='12', md='12')
              v-textarea(solo, auto-grow, dense, row-height='12', v-model='nextTreatmentPlan')
      v-card-actions
        v-spacer
        v-btn(text, @click='dialog = false') {{ $t("drCancel") }}
        v-btn(text, @click='printPdf()') {{ $t("viewreport") }}
  LoadingPage(v-if='fullscreenLoading')
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { treatment } from './../../api'
import LoadingPage from '../../components/common/LoadingPage.vue'
export default {
  props: {
    patientId: {
      type: String,
      default: ''
    },
    treatmentId: {
      type: String,
      default: ''
    },
    pdfDomResult: {},
    printDom: {},
    chartDom: {},
    pdfDomGraph: {},
    radarSeries: {
      type: Array,
      default: () => []
    },
    detailName: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    dialog: false,
    treatmentSheet: {},
    treatmentTarget: '',
    treatmentContent: '',
    effect: '',
    nextTreatmentPlan: '',
    fullscreenLoading: false
  }),

  mounted() {
    this.treatmentTarget =
      this.treatment.target == undefined || this.treatment.target == '' ? this.treatment.app.target : this.treatment.target
    this.treatmentContent =
      this.treatment.content == undefined || this.treatment.content == '' ? this.treatment.app.content : this.treatment.content
    this.effect = this.treatment.effect == '' || this.treatment.effect == undefined ? this.$t('favorable') : this.treatment.effect
    this.nextTreatmentPlan = this.treatment.plan == '' || this.treatment.effect == undefined ? this.$t('continueTreatment') : this.treatment.plan
  },

  components: {
    LoadingPage
  },

  methods: {
    ...mapActions({
      updateTreatment: 'treatments/updateTreatment'
    }),

    async printPdf() {
      await treatment.treatmentSheet({
        treatmentId: parseInt(this.treatmentId),
        effect: this.effect,
        plan: this.nextTreatmentPlan,
        target: this.treatmentTarget,
        content: this.treatmentContent
      })

      this.updateTreatment(this.treatmentId)

      if (this.pdfDomResult == null) {
        window.localStorage.setItem('pdfDomResult', this.pdfDomResult)
      } else {
        window.localStorage.setItem('pdfDomResult', this.pdfDomResult.outerHTML)
      }
      if (this.printDom == null) {
        window.localStorage.setItem('printDom', this.printDom)
      } else {
        window.localStorage.setItem('printDom', this.printDom.outerHTML)
      }
      if (this.pdfDomGraph == null) {
        window.localStorage.setItem('pdfDomGraph', this.pdfDomGraph)
      } else {
        window.localStorage.setItem('pdfDomGraph', this.pdfDomGraph.outerHTML)
      }

      this.$router.push({
        path: `/treatments/${this.$route.query.treatmentId}`,
        query: {
          type: 'print',
          appId: this.$route.query.appId,
          patientId: this.$route.query.patientId,
          treatmentId: this.$route.query.treatmentId,
          detailName: JSON.stringify(this.detailName)
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentById: 'treatments/treatmentById',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch'
    }),

    treatment() {
      return this.treatmentById(this.$route.query.treatmentId)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: #000;
  margin-left: 10px;
}
</style>