<template lang="pug">
div(v-if='treatment')
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("preTitle") }}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("reportTitle") }}
    v-spacer
    DeviceMini(v-if='isActive', :deviceId='treatment.device.model.ID')
    v-spacer
    v-tooltip(v-if='isActive', bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn(fab, dark, color='orange', @click='terminate()', v-bind='attrs', v-on='on')
          v-icon mdi-square-rounded
      span {{ $t("terminateTreatment") }}
    div(v-else)
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='printDetail()')
            v-icon mdi-printer
        span {{ $t("actionPrintTooltip") }}
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, disabled, v-bind='attrs', v-on='on')
            v-icon mdi-check
        span {{ $t("trainingCompleted") }}
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-img(v-if='isActive', :src='streamImgSrc && streamImgSrc != "initiating" ? streamImgSrc : getCoverSrc()', height='auto')
        .d-flex.flex-no-wrap.justify-space-between
          v-row.ml-6(align='center')
            v-img(v-if='!isActive', :src='getCoverSrc()', width='64', max-width='64', height='36', max-height='36')
            v-card-title {{ treatment.app.name }}
          v-card-subtitle
            v-chip.ma-1(v-for='(item, i) in getTags(treatment.app.tags)', :key='i') {{ item }}
  v-row
    v-col(v-if='isActive && enabledEEG', xl='12', lg='12', md='12')
      EEGDiagram(:sn='treatment.device.sn')
  v-row
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='teal', dark)
        .d-flex.flex-no-wrap.justify-space-between
          v-card-title {{ $t("trainingTitle") }}
          v-spacer
          .grey.rounded-bl-xl(v-if='!isActive')
            v-card-subtitle {{ $t("trainingEnd") }}
          .warning.rounded-bl-xl(v-else)
            v-card-subtitle {{ $t("isTraining") }}
        v-card-text
          div(v-if='isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-star-box-multiple-outline
              span {{ `${$t("currentMission")}` }}
              v-spacer
              span {{ `${treatment.missionId} / ${treatment.numberOfMissions}` }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer-outline
              span {{ `${$t("currentMissionDuration")}` }}
              v-spacer
              span {{ `${calculateDuration(treatment.missionStartTime)}` }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-bullseye
              span {{ `${$t("missionTargets")}` }}
              v-spacer
              span {{ `${treatment.missionId}` }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer
              span {{ `${$t("totalDuration")}` }}
              v-spacer
              span {{ `${calculateDuration(startTreatmentTime)}` }}
          div(v-if='!isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='!treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingNotCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-star-box-multiple-outline
              span {{ `${$t("numberOfMissions")}` }}
              v-spacer
              span {{ treatment.numberOfMissions }}
            .d-flex.flex-no-wrap.justify-space-between.my-4 
              v-icon.mr-1 mdi-timer
              span {{ `${$t("totalDuration")}` }}
              v-spacer
              span {{ totalDuration }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-lightbulb-on-outline
              span {{ $t("correctRate") }}
              v-spacer
              span {{ `${averageAccuracy} %` }}
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='cyan darken-3', dark)
        v-card-title {{ $t("configTitle") }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-monitor-eye
            span {{ $t("monitor") }}
            v-spacer
            span(v-if='config && config.monitor') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            img(src='@/assets/hand-icon.png', width='20', style='margin-right: 5px')
            span {{ $t("useHand") }}
            v-spacer
            span(v-if='config && config.useHand == "0"') {{ $t("leftHand") }}
            span(v-if='config && config.useHand == "1"') {{ $t("rightHand") }}
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='blue-grey', dark)
        v-card-title {{  typeUsername == '1' ? $t("userNameTitle") : $t("patientTitle")  }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.name')
            v-icon.mr-1 mdi-account
            span {{ $t("patientName") }}
            v-spacer
            span {{ treatment.patient.name }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.patientNumber && bedNumberName != "false"')
            v-icon.mr-1 mdi-clipboard-account
            span {{  bedNumberName == 'true' ? (typeUsername == '1'? $t("userNameNumber") : $t("patientNumber")) : bedNumberName  }}
            v-spacer
            span {{ treatment.patient.patientNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.bedNumber && partition !== "false"')
            v-icon.mr-1 mdi-bed
            span {{ partition === "true" || partition == "" ? $t("subregion") : partition }}
            v-spacer
            span {{ treatment.patient.bedNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.birthday && enableAge')
            v-icon.mr-1 mdi-calendar
            span {{ $t("patientAge") }}
            v-spacer
            span {{ Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) : "" }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.gender')
            v-icon.mr-1 mdi-gender-male-female
            span {{ $t("patientGender") }}
            v-spacer
            span {{ treatment.patient.gender }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.maritalStatus')
            v-icon.mr-1 mdi-human-male-female
            span {{ $t("patientMaritalStatus") }}
            v-spacer
            span {{ treatment.patient.maritalStatus }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.ethnicity')
            v-icon.mr-1 mdi-map-marker
            span {{ $t("patientEthnicity") }}
            v-spacer
            span {{ treatment.patient.ethnicity }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.education')
            v-icon.mr-1 mdi-school
            span {{ $t("patientEducation") }}
            v-spacer
            span {{ treatment.patient.education }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.remarks')
            v-icon.mr-1 mdi-pencil-box
            span {{ $t("patientRemarks") }}
            v-spacer
            span {{ treatment.patient.remarks }}
    //- 雷达图
  <!-- :style="{position: 'absolute', left: (isOpen ? '-100px' : '50%'),transform: (isOpen ? 'translateX(0)' : 'translateX(-50%)')}" -->
  v-row
    v-col(xl='12', lg='12', md='12', v-show='radarShow')
      v-card(hover)
        v-card-title {{ $t("recordScoreGraph") }}
        v-card-text(style='display: flex')
          .radarChart
            apexchart.radarLeft(
              ref='chart',
              type='radar',
              :options='radarChartOptions',
              :width='isOpen ? 600 : 400',
              height='400',
              :series='radarSeries',
              :style='{ margin: isOpen ? "0" : "0 auto !important" }'
            )
            #printDom.radarRight(:style='{ marginTop: isOpen ? "0" : "0" }')
              .right
                .box
                  apexchart(type='donut', :options='donutChartOptions', :series='donutSeries', width='100%')
                  //- (:style='{ width: isOpen ? "300px" : "140px" }')
                  .radarName

                    span.name(:style='{ fontSize: isOpen ? "36px" : "32px" }') {{ $t("attention") }}
                .box
                  apexchart(type='donut', :options='donutChartOptions1', :series='donutSeries1')
                  .radarName
                    span.name(:style='{ fontSize: isOpen ? "36px" : "32px" }') {{ $t("execution") }}
                .box
                  apexchart(type='donut', :options='donutChartOptions2', :series='donutSeries2')
                  .radarName
                    span.name(:style='{ fontSize: isOpen ? "36px" : "32px" }') {{ $t("calculation") }}
                .box
                  apexchart(type='donut', :options='donutChartOptions3', :series='donutSeries3')
                  .radarName
                    span.name(:style='{ fontSize: isOpen ? "36px" : "32px" }') {{ $t("memory") }}
                .box
                  apexchart(type='donut', :options='donutChartOptions4', :series='donutSeries4')
                  .radarName
                    span.name(:style='{ fontSize: isOpen ? "36px" : "32px" }') {{ $t("orientation") }}
              .radarDot(:style='{ marginTop: isOpen ? "84px" : "50px", fontSize: isOpen ? "28px" : "24px" }')

                div(style='margin-right: 15px')
                  span.blue(:style='{ width: isOpen ? "12px" : "10px", height: isOpen ? "12px" : "10px" }')
                  span {{ $t("excellentCapacity") }}（80-100）
                div(style='margin-right: 15px')
                  span.green(:style='{ width: isOpen ? "12px" : "10px", height: isOpen ? "12px" : "10px" }')
                  span {{ $t("intactCapacity") }}（60-79）
                div(style='margin-right: 15px')
                  span.Cyan(:style='{ width: isOpen ? "12px" : "10px", height: isOpen ? "12px" : "10px" }')
                  span {{ $t("mildlyImpaired") }}（40-59）
                div(style='margin-right: 15px')
                  span.orange1(:style='{ width: isOpen ? "12px" : "10px", height: isOpen ? "12px" : "10px" }')
                  span {{ $t("moderatelyImpaired") }}（20-39）
                div
                  span.red(:style='{ width: isOpen ? "12px" : "10px", height: isOpen ? "12px" : "10px" }')
                  span {{ $t("severelyImpaired") }}（0-19）
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("recordGraph") }}
        v-card-text
          apexchart#pdfDomGraph(ref='chart', type='line', height='400', width='100%', :options='chartOptions', :series='series')
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("recordTitle") }}
        v-divider
        v-timeline(v-for='item in records', :key='item.model.ID', align-top, dense)
          v-timeline-item(color='grey lighten-2', fill-dot, small)
            v-row
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      v-icon.mr-1 mdi-star-box-multiple-outline
                      span {{ item.data.missionId }}
                      .text-caption {{ item.data.missionTag }}
                  span.report-legend {{ $t("currentMission") }}
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      v-icon.mr-1 mdi-timer-outline
                      span {{ `${item.data.missionDuration}s` }}
                  span {{ $t("timeConsuming") }}
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      img(src='@/assets/score.png', width='22', style='margin-right: 5px; position: relative; top: 5px')
                      span {{ item.data.score }}
                  span {{ $t("trainingScore") }}
              v-col
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    div(v-bind='attrs', v-on='on')
                      img(src='@/assets/task.png', width='20', style='margin-right: 5px; position: relative; top: 5px')
                      span {{ item.data.isFinish == true ? $t('yes') : $t('no') }}
                  span {{ $t("taskCompletion") }}
                  v-row
    v-col(xl='12', lg='12', md='12', v-show='showContent', v-if='!isActive')
      v-card(hover)
        v-card-title {{ $t("recommendedTrainingContent") }}
          v-card-text.recommonContent
            v-card(style='margin-bottom: 20px; margin-right: 15px', v-for='(x, i) in appsList', :key='i')
              v-img(:src='getCoverSrc1(x)', width='300', height='168.75')
                .durationBox {{ x.dimension == "1" ? $t('attention') : x.dimension == "2" ? $t('execution') : x.dimension == "4" ? $t('calculation') : x.dimension == "3" ? $t('memory') : x.dimension == "5" ? $t('orientation') : "" }}
              v-card-title(style='font-size: 16px') {{ x.name }}
                v-spacer
                v-btn(color='primary', @click='start(x)')
                  v-icon(left, style='font-size: 22px') mdi-play-circle
                  |
                  | {{ $t("startTraining") }}

    NewTreatment(ref='newTreatment')
    TreatmentDetailReport(
      ref='treatmentReport',
      :patientId='$route.query.patientId',
      :treatmentId='$route.query.treatmentId',
      :printDom='printDom',
      :pdfDomGraph='pdfDomGraph',
      :radarSeries='radarSeries',
      :detailName='detailName',
      :chartDom='chartDom'
    )
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { mapActions, mapGetters } from 'vuex'
import { treatment, record } from '../api'
import { connectStreamer } from '../lib/fmetp'

import DeviceMini from './DeviceMini'
import EEGDiagram from './EEGDiagram.vue'
import VueApexCharts from 'vue-apexcharts'
import NewTreatment from './NewTreatment'
import { getStartTreatmentTime } from '../lib/start-treatment-time'
import TreatmentDetailReport from './Treatments/TreatmentDetailReport.vue'

export default {
  data: (vm) => ({
    now: moment(),
    isActive: undefined,
    totalDuration: 0,
    socket: undefined,
    streamImgSrc: undefined,
    chartOptions: {
      legend: {
        fontSize: '30px'
      },
      chart: {
        type: 'line',
        stacked: false,
        toolbar: false
      },
      stroke: {
        curve: 'smooth',
        width: [1, 2, 2]
      },
      plotOptions: {
        bar: {
          columnWidth: '5%',
          endingShape: 'rounded'
        }
      },
      yaxis: [
        {
          forceNiceScale: true,
          type: 'area',
          labels: {
            style: {
              colors: '#008FFB',
              fontSize: '36px'
            }
          },
          title: {
            text: vm.$t('currentMissionDuration'),
            rotate: 360,
            offsetX: 35,
            offsetY: 200,
            style: {
              color: '#008FFB',
              fontSize: '36px'
            }
          }
        },
        {
          opposite: true,
          tickAmount: 3,
          labels: {
            style: {
              colors: '#FEB019',
              fontSize: '36px'
            }
          },
          title: {
            text: vm.$t('score'),
            rotate: 360,
            offsetX: -35,
            offsetY: 200,
            style: {
              color: '#FEB019',
              fontSize: '36px'
            }
          }
        }
      ]
    },
    radarChartOptions: {
      chart: {
        type: 'radar',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      markers: {
        size: 3,
        hover: {
          size: 5
        }
      },
      xaxis: {
        categories: [vm.$t('attention'), vm.$t('execution'), vm.$t('calculation'), vm.$t('memory'), vm.$t('orientation')]
      },
      yaxis: [
        {
          min: 0,
          max: 100,
          tickAmount: 5,
          show: true,
          opposite: true
        }
      ]
    },

    dimension: [1, 2, 4, 3, 5],
    cognitive: [],
    type: '',
    appsList: [],
    treatmentRecords: [],
    color: '',
    radarShow: true,
    showContent: true,
    getScoreData: [],
    dimensionData: [],
    startTreatmentTime: '',
    isOpen: false,
    printDom: '',
    pdfDomGraph: '',
    chartDom: '',
    detailName: 'cognitive',
    nowTimer: undefined
  }),

  async created() {
    momentDurationFormatSetup(moment)
    this.nowTimer = this.$mySetInterval(() => (this.now = moment()))
    this.fetchRecords(this.$route.params.id)
    this.startTreatmentTime = getStartTreatmentTime()
  },

  async mounted() {
    // if (this.radarShow) {
    //   this.pdfDomGraph = document.querySelector('#pdfDomGraph')
    //   this.printDom = ''
    // } else {
    //   this.printDom = document.querySelector('#printDom')
    //   this.pdfDomGraph = ''
    // }
    this.pdfDomGraph = document.querySelector('#pdfDomGraph')
    this.printDom = document.querySelector('#printDom')
    this.chartDom = this.$refs.chart.$el
    this.resize()
    window.addEventListener('resize', this.resize, false)
    await this.$nextTick()
    this.streamTreatment()
    const id = this.$route.params.id
    const appId = parseInt(this.$route.query.appId)
    const patientId = parseInt(this.$route.query.patientId)
    const result1 = await treatment.getScore({ appId: appId, patientId: patientId })
    this.dimensionData = result1.data
    if (result1.data.length > 0) {
      const data = result1.data.filter(x => {
        ``
        return x.score !== 0
      })
      this.getScoreData = data
    }
    this.dimensionData.forEach(x => {
      if (x.model.ID == id) {
        if (x.dimension == '') {
          this.radarShow = false
          this.type = this.dimension.join(',')
        } else {
          this.radarShow = true
          let arr = []
          let obj = JSON.parse(x.dimension).dimensionAverage
          for (let key in obj) {
            arr.push(obj[key])
          }
          arr.forEach((x, i) => {
            if (x < 60) {
              this.cognitive.push(this.dimension[i])
            }
            if (this.cognitive.length > 0) {
              this.showContent = true
              this.type = this.cognitive.join(',')
            } else {
              this.showContent = false
              this.type = '0'
            }
          })
        }
      }
    })

    const result = await record.dimension({ dimension: this.type, treatmentId: this.treatment.model.ID })
    this.appsList = result.data
  },

  destroyed() {
    this.socket.close()
  },

  methods: {
    ...mapActions({
      fetchRecords: 'records/indexByTreatment'
    }),

    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },

    start(item) {
      this.$refs.newTreatment.preAppId = item?.model.ID
      this.$refs.newTreatment.dialog = true
    },

    getCoverSrc1(item) {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${item.cover}`
    },

    async getChartImage() {
      const dataURI = await this.$refs.chart.dataURI()
      const imgURI = dataURI.imgURI
      return imgURI
    },

    async printDetail() {
      this.$refs.treatmentReport.dialog = true
    },

    goBack() {
      this.$router.go(-1)
    },

    async terminate() {
      const treatmentId = this.treatment?.model.ID
      await treatment.terminate(treatmentId)
      clearInterval(this.nowTimer)
      this.socket.close()
    },

    getCoverSrc() {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${this.treatment.app.cover}`
    },

    streamTreatment() {
      const treatmentId = this.treatment?.model.ID.toString()
      this.streamImgSrc = 'initiating'
      this.socket = connectStreamer(treatmentId, src => (this.streamImgSrc = src))
    },

    getTags(tags) {
      return tags.split(';')
    },

    calculateDuration(then) {
      if (this.now?.diff && moment(then).year() >= 2021) {
        return moment.duration(this.now.diff(then)).format(this.$t('hHourmmMinssSecond'))
      } else {
        return NaN
      }
    },

    calculateDurationBetween(start, end) {
      if (moment.duration(moment(end).diff(start))._milliseconds > 0) {
        if (start.slice(0, 10) !== '0001-01-01' && end) {
          return moment.duration(moment(end).diff(start)).format(this.$t('hHourmmMinssSecond'))
        } else {
          return NaN
        }
      } else {
        return 0 + this.$t('second')
      }
    },

    readableDuration(seconds) {
      return moment.duration(parseInt(seconds), 'seconds').format(this.$t('hHourmmMinssSecond'))
    }
  },

  watch: {
    treatment: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.isActive = newVal.active
        if (!this.isActive) {
          this.socket?.close()
        }
        this.totalDuration = this.calculateDurationBetween(newVal.applicationStartTime, newVal.applicationEndTime)
      }
    }
  },

  computed: {
    ...mapGetters({
      treatmentById: 'treatments/training',
      recordsByTreatmentId: 'records/recordsByTreatmentId',
      enabledEEG: 'setting/enabledEEG',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      bedNumberName: 'setting/bedNumberName',
      enableAge: 'setting/enableAge'
    }),

    treatment() {
      return this.treatmentById(this.$route.params.id)
    },

    totalNumberOfWrongSelections() {
      return this.records.reduce((sum, x) => sum + x.data.numberOfErrors, 0)
    },

    averageAccuracy() {
      return this.records.length === 0
        ? 0
        : (
          this.records.reduce((sum, x) => sum + Number(x.data.accuracy.slice(0, x.data.accuracy.length - 1)), 0) / this.records.length
        ).toFixed(2)
    },

    config() {
      if (this.treatment.settings) {
        return JSON.parse(this.treatment.settings)
      } else {
        return null
      }
    },

    records() {
      return this.recordsByTreatmentId(this.$route.params.id)
    },

    averageDuration() {
      return this.readableDuration(this.records.reduce((sum, x) => sum + x.data.missionDuration, 0) / this.records.length)
    },

    series() {
      if (this.records.length < 0) return []
      return [
        {
          color: '#008FFB',
          name: this.$t('currentMissionDuration'),
          type: 'area',
          data: this.records.reduce((arr, x) => [...arr, x.data.missionDuration], []),
        },
        {
          color: '#FEB019',
          name: this.$t('score'),
          type: 'line',
          data: this.records.reduce((arr, x) => [...arr, x.data.score], [])
        }
      ]
    },

    radarSeries() {
      const arr = []
      let obj = {}
      const id = this.$route.params.id
      this.dimensionData.forEach(x => {
        if (x.dimension !== '') {
          if (x.model.ID == id) {
            obj = JSON.parse(x.dimension).dimensionAverage
          }
        }
      })
      for (let key in obj) {
        arr.push(obj[key])
      }

      return [
        {
          name: this.$t('currentAssessment'),
          data: arr
        }
      ]
    },

    donutSeries() {
      if (this.records.length < 0) return []
      return [this.radarSeries[0].data[0], 100 - this.radarSeries[0].data[0], 0, 0, 0]
    },

    donutSeries1() {
      if (this.records.length < 0) return []
      return [this.radarSeries[0].data[1], 100 - this.radarSeries[0].data[1], 0, 0, 0]
    },

    donutSeries2() {
      if (this.records.length < 0) return []
      return [this.radarSeries[0].data[2], 100 - this.radarSeries[0].data[2], 0, 0, 0]
    },
    donutSeries3() {
      if (this.records.length < 0) return []
      return [this.radarSeries[0].data[3], 100 - this.radarSeries[0].data[3], 0, 0, 0]
    },

    donutSeries4() {
      if (this.records.length < 0) return []
      return [this.radarSeries[0].data[4], 100 - this.radarSeries[0].data[4], 0, 0, 0]
    },

    donutChartOptions() {
      let num = this.radarSeries[0].data[0]
      let color = ''
      if (num >= 80 && num <= 100) {
        color = '#008FFB'
      } else if (num >= 60 && num < 80) {
        color = '#64EB81'
      } else if (num >= 40 && num < 60) {
        color = '#64EBDB'
      } else if (num >= 20 && num < 40) {
        color = '#F87933'
      } else {
        color = '#F83E33'
      }
      return {
        colors: [color, '#F5F5F5'],
        title: {
          text: num,
          align: 'center',
          offsetX: 0,
          offsetY: this.isOpen ? 102 : 55,
          style: {
            fontSize: '36px',
            fontWeight: 'normal',
            fontFamily: undefined,
            color: '#000'
          }
        },
        chart: {
          type: 'donut',
          width: this.isOpen ? 240 : 140
        },
        tooltip: {
          enabled: false // 禁用鼠标移入显示
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        }
      }
    },

    donutChartOptions1() {
      let num = this.radarSeries[0].data[1]
      let color = ''
      if (num >= 80 && num <= 100) {
        color = '#008FFB'
      } else if (num >= 60 && num < 80) {
        color = '#64EB81'
      } else if (num >= 40 && num < 60) {
        color = '#64EBDB'
      } else if (num >= 20 && num < 40) {
        color = '#F87933'
      } else {
        color = '#F83E33'
      }
      return {
        colors: [color, '#F5F5F5'],
        title: {
          text: num,
          align: 'center',
          offsetX: 0,
          offsetY: this.isOpen ? 102 : 55,
          style: {
            fontSize: '36px',
            fontWeight: 'normal',
            fontFamily: undefined,
            color: '#000'
          }
        },
        chart: {
          type: 'donut',
          width: this.isOpen ? 240 : 140
        },
        tooltip: {
          enabled: false // 禁用鼠标移入显示
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        }
      }
    },

    donutChartOptions2() {
      let num = this.radarSeries[0].data[2]
      let color = ''
      if (num >= 80 && num <= 100) {
        color = '#008FFB'
      } else if (num >= 60 && num < 80) {
        color = '#64EB81'
      } else if (num >= 40 && num < 60) {
        color = '#64EBDB'
      } else if (num >= 20 && num < 40) {
        color = '#F87933'
      } else {
        color = '#F83E33'
      }
      return {
        colors: [color, '#F5F5F5'],
        title: {
          text: num,
          align: 'center',
          offsetX: 0,
          offsetY: this.isOpen ? 102 : 55,
          style: {
            fontSize: '36px',
            fontWeight: 'normal',
            fontFamily: undefined,
            color: '#000'
          }
        },
        chart: {
          type: 'donut',
          width: this.isOpen ? 240 : 140
        },
        tooltip: {
          enabled: false // 禁用鼠标移入显示
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        }
      }
    },

    donutChartOptions3() {
      let num = this.radarSeries[0].data[3]
      let color = ''
      if (num >= 80 && num <= 100) {
        color = '#008FFB'
      } else if (num >= 60 && num < 80) {
        color = '#64EB81'
      } else if (num >= 40 && num < 60) {
        color = '#64EBDB'
      } else if (num >= 20 && num < 40) {
        color = '#F87933'
      } else {
        color = '#F83E33'
      }
      return {
        colors: [color, '#F5F5F5'],
        title: {
          text: num,
          align: 'center',
          offsetX: 0,
          offsetY: this.isOpen ? 102 : 55,
          style: {
            fontSize: '36px',
            fontWeight: 'normal',
            fontFamily: undefined,
            color: '#000'
          }
        },
        chart: {
          type: 'donut',
          width: this.isOpen ? 240 : 140
        },
        tooltip: {
          enabled: false // 禁用鼠标移入显示
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        }
      }
    },

    donutChartOptions4() {
      let num = this.radarSeries[0].data[4]
      let color = ''
      if (num >= 80 && num <= 100) {
        color = '#008FFB'
      } else if (num >= 60 && num < 80) {
        color = '#64EB81'
      } else if (num >= 40 && num < 60) {
        color = '#64EBDB'
      } else if (num >= 20 && num < 40) {
        color = '#F87933'
      } else {
        color = '#F83E33'
      }
      return {
        colors: [color, '#F5F5F5'],
        title: {
          text: num,
          align: 'center',
          offsetX: 0,
          offsetY: this.isOpen ? 102 : 55,
          style: {
            fontSize: '36px',
            fontWeight: 'normal',
            fontFamily: undefined,
            color: '#000'
          }
        },
        chart: {
          type: 'donut',
          width: this.isOpen ? 240 : 140
        },
        tooltip: {
          enabled: false // 禁用鼠标移入显示
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        }
      }
    },

    ScoreSeries() {
      if (this.getScoreData.length < 0) return []
      return [
        {
          color: '#008FFB',
          name: this.$t('trainingScore'),
          type: 'bar',
          data: this.getScoreData.reduce((arr, x) => [...arr, x.score], [])
        }
      ]
    },
    chartScoreOptions() {
      return {
        legend: {
          textStyle: { fontSize: 40 }
        },
        chart: {
          id: 'vuechart-example',
          stacked: false,
          toolbar: false
        },
        stroke: {
          curve: 'smooth',
          width: [4]
        },
        plotOptions: {
          bar: {
            columnWidth: '5%',
            endingShape: 'rounded'
          }
        },
        yaxis: {
          forceNiceScale: true,
          type: 'bar',
          labels: {
            style: {
              colors: '#008FFB',
              fontSize: '36px'
            }
          },
          title: {
            text: this.$t('historyScore'),
            rotate: 360,
            offsetX: 35,
            offsetY: 200,
            style: {
              color: '#008FFB',
              fontSize: '36px'
            }
          },
          tickAmount: 6,
          max: 100,
          min: 0
        },
        xaxis: {
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          categories: this.getScoreData.length === 0 ? [''] : this.getScoreData.map(x => x.model.CreatedAt.split('T')[0].replace(/-/g, '/'))
        }
      }
    }
  },

  components: {
    DeviceMini,
    EEGDiagram,
    apexchart: VueApexCharts,
    NewTreatment,
    TreatmentDetailReport
  }
}
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-xaxis-texts-g {
  display: none !important;
}


.durationBox {
  position: absolute;
  bottom: 8px;
  right: 16px;
  padding: 2px 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.85);
}

.recommonContent {
  display: flex;
  flex-wrap: wrap;
  padding-right: 0;
}

::v-deep .apexcharts-toolbar {
  display: none;
}

.radarChart {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.right {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.name {
  text-align: center;
  color: #000;
  font-size: 18px;
}

.radarName {
  text-align: center;
}

.radarDot {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.blue,
.green,
.Cyan,
.orange1,
.red {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}

.blue {
  background: blue;
}

.green {
  background: green;
}

.orange1 {
  background: #f87933;
}

.Cyan {
  background: #64ebdb;
}

.red {
  background: red;
}
</style>
