<template lang="pug">
div(v-if='treatment')
  v-toolbar.mb-6(flat)
    v-btn.ma-2(large, color='orange', dark, @click='goBack()')
      v-icon(dark, left) mdi-arrow-left
      | {{ $t("preTitle") }}
    v-divider.mx-4(inset, vertical)
    v-icon.mr-1 mdi-file-chart
    v-toolbar-title {{ $t("reportTitle") }}
    v-spacer
    DeviceMini(v-if='isActive', :deviceId='treatment.device.model.ID')
    v-spacer
    v-tooltip(v-if='isActive', bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn(fab, dark, color='orange', @click='terminate()', v-bind='attrs', v-on='on')
          v-icon mdi-square-rounded
      span {{ $t("terminateTreatment") }}
    div(v-else)
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.primary.mr-2(fab, v-bind='attrs', v-on='on', @click='printDetail()')
            v-icon mdi-printer
        span {{ $t("actionPrintTooltip") }}
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, disabled, v-bind='attrs', v-on='on')
            v-icon mdi-check
        span {{ $t("trainingCompleted") }}
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-img(v-if='isActive', :src='streamImgSrc && streamImgSrc != "initiating" ? streamImgSrc : getCoverSrc()', height='auto')
          .countdown(v-if='enabledCountdown') {{ countdownRes }}
        .d-flex.flex-no-wrap.justify-space-between
          v-row.ml-6(align='center')
            v-img(v-if='!isActive', :src='getCoverSrc()', width='64', max-width='64', height='36', max-height='36')
            v-card-title {{ treatment.app.name }}
          v-card-subtitle
            v-chip.ma-1(v-for='(item, i) in getTags(treatment.app.tags)', :key='i') {{ item }}
  v-row
    v-col(v-if='isActive && enabledEEG', xl='12', lg='12', md='12')
      EEGDiagram(:sn='treatment.device.sn')
  v-row
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='teal', dark)
        .d-flex.flex-no-wrap.justify-space-between
          v-card-title {{ $t("trainingTitle") }}
          v-spacer
          .grey.rounded-bl-xl(v-if='!isActive')
            v-card-subtitle {{ $t("trainingEnd") }}
          .warning.rounded-bl-xl(v-else)
            v-card-subtitle {{ $t("isTraining") }}
        v-card-text
          div(v-if='isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer
              span {{ `${$t("totalDuration")}` }}
              v-spacer
              span {{ `${calculateDuration(startTreatmentTime)}` }}
          div(v-if='!isActive')
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='!treatment.complete')
              v-icon.mr-1 mdi-check-circle-outline
              span {{ `${$t("isTrainingCompleted")}` }}
              v-spacer
              span {{ $t("trainingNotCompleted") }}
            .d-flex.flex-no-wrap.justify-space-between.my-4
              v-icon.mr-1 mdi-timer
              span {{ `${$t("totalDuration")}` }}
              v-spacer
              span {{ totalDuration }}
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='cyan darken-3', dark)
        v-card-title {{ $t("configTitle") }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-monitor-eye
            span {{ $t("monitor") }}
            v-spacer
            span(v-if='config && config.monitor') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            img(src='@/assets/score-1.png', width='24', style='margin-right: 5px')
            span {{ $t("scoreOn") }}
            v-spacer
            span(v-if='config && config.scoreSwitch') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            img(src='@/assets/breath.png', width='24', style='margin-right: 5px')
            span {{ $t("breath") }}
            v-spacer
            span(v-if='config && config.breathMonitor') {{ $t("on") }}
            span(v-else) {{ $t("off") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            v-icon.mr-1 mdi-timer-outline
            span {{ $t("timeLimit") }}
            v-spacer
            span(v-if='config && config.timeLimit == -1') {{ $t("infinite") }}
            span(v-if='config && config.timeLimit == 15') {{ $t("15min") }}
            span(v-if='config && config.timeLimit == 20') {{ $t("20min") }}
            span(v-if='config && config.timeLimit == 30') {{ $t("30min") }}
          .d-flex.flex-no-wrap.justify-space-between.my-4
            img(src='@/assets/voice.png', width='24', style='margin-right: 5px')
            span {{ $t("voiceType") }}
            v-spacer
            span(v-if='config && config.voiceType == 0') {{ $t("close") }}
            span(v-if='config && config.voiceType == 1') {{ $t("mindfulness") }}
            span(v-if='config && config.voiceType == 2') {{ $t("hypnosis") }}
    v-col(xl='4', lg='4', md='12')
      v-card.mx-auto(hover, color='blue-grey', dark)
        v-card-title {{ $t("patientTitle") }}
        v-divider
        v-card-text
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.name')
            v-icon.mr-1 mdi-account
            span {{ $t("patientName") }}
            v-spacer
            span {{ treatment.patient.name }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.patientNumber')
            v-icon.mr-1 mdi-clipboard-account
            span {{ $t("patientNumber") }}
            v-spacer
            span {{ treatment.patient.patientNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.bedNumber && partition !== "false"')
            v-icon.mr-1 mdi-bed
            span {{ partition === "true" || partition == "" ? $t("subregion") : partition }}
            v-spacer
            span {{ treatment.patient.bedNumber }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.birthday && enableAge')
            v-icon.mr-1 mdi-calendar
            span {{ $t("patientAge") }}
            v-spacer
            span {{ Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(treatment.patient.birthday).getTime()) / 3.15576e10) : "" }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.gender')
            v-icon.mr-1 mdi-gender-male-female
            span {{ $t("patientGender") }}
            v-spacer
            span {{ treatment.patient.gender }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.maritalStatus')
            v-icon.mr-1 mdi-human-male-female
            span {{ $t("patientMaritalStatus") }}
            v-spacer
            span {{ treatment.patient.maritalStatus }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.ethnicity')
            v-icon.mr-1 mdi-map-marker
            span {{ $t("patientEthnicity") }}
            v-spacer
            span {{ treatment.patient.ethnicity }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.education')
            v-icon.mr-1 mdi-school
            span {{ $t("patientEducation") }}
            v-spacer
            span {{ treatment.patient.education }}
          .d-flex.flex-no-wrap.justify-space-between.my-4(v-if='treatment.patient.remarks')
            v-icon.mr-1 mdi-pencil-box
            span {{ $t("patientRemarks") }}
            v-spacer
            span {{ treatment.patient.remarks }}
  v-row
    v-col(xl='12', lg='12', md='12', v-if='getRecords.length > 0 && getRecords[getRecords.length - 1].data.pictrue !== null')
      v-card.mx-auto(hover)
        v-card-title {{ $t("recordScoreGraph") }}
        v-card-text
          v-img(:src='getResultSrc()')
  v-row
    v-col(xl='12', lg='12', md='12')
      v-card.mx-auto(hover)
        v-card-title {{ $t("recordGraph") }}
        v-card-text
          apexchart#pdfDomGraph(ref='chart', type='line', height='400', :options='chartOptions', :series='series')
  TreatmentDetailReport(
    ref='treatmentReport',
    :patientId='$route.query.patientId',
    :treatmentId='$route.query.treatmentId',
    :printDom='printDom',
    :pdfDomGraph='pdfDomGraph'
  )
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { VUE_APP_FILE_SERVER_ENDPOINT } from '@/app.config'
import { VUE_APP_FILE_SERVER_IMGRESULT } from '@/app.config'
import { mapActions, mapGetters } from 'vuex'
import { treatment } from '../api'
import { connectStreamer } from '../lib/fmetp'
import DeviceMini from './DeviceMini'
import EEGDiagram from './EEGDiagram.vue'
import VueApexCharts from 'vue-apexcharts'
import { getStartTreatmentTime } from '../lib/start-treatment-time'
import TreatmentDetailReport from './Treatments/TreatmentDetailReport.vue'

export default {
  data: () => ({
    now: moment(),
    isActive: undefined,
    totalDuration: 0,
    socket: undefined,
    streamImgSrc: undefined,
    breathData: [],
    breath1Data: [],
    timeData: [],
    time1Data: [],
    timer: null,
    idxIndex: 0,
    startTreatmentTime: '',
    printDom: '',
    pdfDomGraph: '',
    clearTimer: undefined,
    nowTimer: undefined,
    startTime: '',
    countdownRes: '',
    timestamp: 0
  }),

  beforeDestroy() {
    clearInterval(this.clearTimer)
    clearInterval(this.nowTimer)
  },

  created() {
    momentDurationFormatSetup(moment)
    this.nowTimer = this.$mySetInterval(() => (this.now = moment()))
    this.fetchRecords(this.$route.params.id)
    this.startTreatmentTime = getStartTreatmentTime()

    if (this.enabledCountdown && this.isActive) {
      this.clearTimer = this.$mySetInterval(() => {
        setTimeout(() => {
          this.startTime = this.calculateDuration(this.treatment.applicationStartTime)
          this.getTimeGap(JSON.parse(this.treatment.settings).timeLimit, this.startTime)
        }, 0)
      })
    }
  },

  async mounted() {
    await this.$nextTick()
    this.streamTreatment()
    this.pdfDomGraph = document.querySelector('#pdfDomGraph')
  },

  destroyed() {
    this.socket.close()
  },

  methods: {
    ...mapActions({
      fetchRecords: 'records/indexByTreatment'
    }),

    async getTimeGap(currentTime, pastTime) {
      let pattern = /\d+/g
      let match = pastTime.match(pattern)
      var minutes = 0
      var seconds = 0
      if (match.length == 1) {
        seconds = parseInt(match[0])
      } else if (match.length == 2) {
        minutes = parseInt(match[0])
        seconds = parseInt(match[1])
      }
      var now = new Date()
      var pastTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), minutes, seconds).getTime()
      var currentTimestamp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), currentTime, 0).getTime()
      this.timestamp = currentTimestamp - pastTimestamp
      if (this.timestamp > 0) {
        let date = new Date(this.timestamp)
        this.countdownRes = date.getMinutes() + this.$t('minute') + date.getSeconds() + this.$t('second')
      } else {
        this.countdownRes = 0 + this.$t('minute') + 0 + this.$t('second')
      }
    },

    async getChartImage() {
      const dataURI = await this.$refs.chart.dataURI()
      const imgURI = dataURI.imgURI
      return imgURI
    },

    getResultSrc() {
      return `${VUE_APP_FILE_SERVER_IMGRESULT}/assets/${this.records[this.records.length - 1].data.pictrue + '.png'}`
    },

    async printDetail() {
      this.$refs.treatmentReport.dialog = true
    },

    goBack() {
      this.$router.go(-1)
      clearInterval(this.clearTimer)
      clearInterval(this.nowTimer)
    },

    async terminate() {
      const treatmentId = this.treatment?.model.ID
      await treatment.terminate(treatmentId)
      clearInterval(this.clearTimer)
      clearInterval(this.nowTimer)
      this.socket.close()
    },

    getCoverSrc() {
      return `${VUE_APP_FILE_SERVER_ENDPOINT}/${this.treatment.app.cover}`
    },

    streamTreatment() {
      const treatmentId = this.treatment?.model.ID.toString()
      this.streamImgSrc = 'initiating'
      this.socket = connectStreamer(treatmentId, src => (this.streamImgSrc = src))
    },

    getTags(tags) {
      return tags.split(';')
    },

    calculateDuration(then) {
      if (this.now?.diff && moment(then).year() >= 2021) {
        return moment.duration(this.now.diff(then)).format(this.$t('hHourmmMinssSecond'))
      } else {
        return NaN
      }
    },

    calculateDurationBetween(start, end) {
      if (moment.duration(moment(end).diff(start))._milliseconds > 0) {
        if (start && end) {
          return moment.duration(moment(end).diff(start)).format(this.$t('hHourmmMinssSecond'))
        } else {
          return NaN
        }
      } else {
        return 0 + this.$t('second')
      }
    },

    readableDuration(seconds) {
      return moment.duration(parseInt(seconds), 'seconds').format(this.$t('hHourmmMinssSecond'))
    }
  },

  watch: {
    treatment: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.isActive = newVal.active
        if (!this.isActive) {
          clearInterval(this.clearTimer)
          clearInterval(this.nowTimer)
          this.socket?.close()
        }
        this.totalDuration = this.calculateDurationBetween(newVal.applicationStartTime, newVal.applicationEndTime)
      }
    }
  },

  computed: {
    ...mapGetters({
      treatmentById: 'treatments/training',
      recordsByTreatmentId: 'records/recordsByTreatmentId',
      enabledEEG: 'setting/enabledEEG',
      partition: 'setting/enabledPartition',
      enableAge: 'setting/enableAge',
      enabledCountdown: 'setting/enabledCountdown'
    }),

    treatment() {
      return this.treatmentById(this.$route.params.id)
    },

    config() {
      if (this.treatment.settings) {
        return JSON.parse(this.treatment.settings)
      } else {
        return null
      }
    },

    records() {
      return this.recordsByTreatmentId(this.$route.params.id)
    },

    averageDuration() {
      return this.readableDuration(this.records.reduce((sum, x) => sum + x.data.timeOffset, 0) / this.getRecords.length)
    },

    getRecords() {
      const result = this.records.filter(item => {
        return item.data.controlType == '0'
      })
      return result
    },

    series() {
      let sum = 0

      if (!this.isActive) {
        this.records.forEach(item => {
          item.data.breath.forEach(x => {
            this.breath1Data.push(x.breath)
          })
        })
        this.breath1Data.forEach((y, index) => {
          if (index % 10 == 0 && index !== 0) {
            this.breathData.push(sum / 10)
            sum = 0
          }
          sum += parseInt(this.breath1Data[index])
        })
      } else {
        const data = []
        this.records.forEach(item => {
          item.data.breath.forEach(x => {
            clearInterval(this.timer)
            data.push(x.breath)

            const arr1 = data.slice(-60, data.length)
            this.timer = this.$mySetInterval(() => {
              this.breathData.push(arr1[this.idxIndex])
              this.idxIndex = this.idxIndex + 1
              if (this.idxIndex === arr1.length) {
                clearInterval(this.timer)
                this.idxIndex = 0
              }
            })
          })
        })
      }

      if (this.records.length < 0) return []
      return [
        {
          name: this.$t('currentMissionDuration'),
          color: '#008FFB',
          type: 'line',
          data: this.breathData
        }
      ]
    },

    chartOptions() {
      if (!this.isActive) {
        this.records.forEach(item => {
          item.data.breath.forEach(x => {
            this.time1Data.push(x.timestamp)
          })
        })
        this.time1Data.forEach((y, index) => {
          if (index % 10 == 0 && index !== 0) {
            this.timeData.push(y.split('T')[0].replace(/-/g, '/') + ' ' + y.slice(11, y.length - 1))
          }
        })
      } else {
        this.records.forEach(item => {
          item.data.breath.forEach(x => {
            this.timeData.push(x.timestamp.split('T')[0].replace(/-/g, '/') + ' ' + x.timestamp.slice(11, x.timestamp.length - 1))
          })
        })
      }
      return {
        chart: {
          type: 'line',
          stacked: false,
          toolbar: false
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          fontSize: '22px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 200,
        },
        stroke: {
          curve: 'smooth',
          width: [1, 2, 2]
        },
        plotOptions: {
          bar: {
            columnWidth: '5%',
            endingShape: 'rounded'
          }
        },
        yaxis: [
          {
            forceNiceScale: true,
            type: 'area',
            labels: {
              style: {
                colors: '#008FFB',
                fontSize: '36px'
              }
            },
            title: {
              text: this.$t('currentMissionDuration'),
              rotate: 360,
              offsetX: 35,
              offsetY: 200,
              style: {
                color: '#008FFB',
                fontSize: '36px'
              }
            }
          }
        ],
        xaxis: {
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          categories: this.timeData
        }
      }
    }
  },

  components: {
    DeviceMini,
    EEGDiagram,
    apexchart: VueApexCharts,
    TreatmentDetailReport
  }
}
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-xaxis-texts-g {
  display: none !important;
}


.countdown {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: red;
  font-weight: bold;
}
</style>
